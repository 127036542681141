.selectLayout {
	position: relative;
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: #FFFFFF;
	z-index: 99;
}

.selectLayout .select {
	padding: 10px 16px;
	background: #3E3E3E;
	border-radius: 12px;
	border: none;
	outline: none;
	color: white;
	cursor: pointer;
}

.selectLayout .options {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	padding: 0 16px;
	background: #3E3E3E;
	border-radius: 12px;
}

.selectLayout .options .option {
	padding: 10px 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	column-gap: 0.5em;
	cursor: pointer;
}