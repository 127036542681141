.tabLayout {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 6px;
	padding: 14px 12px;
	background: #3E3E3E;
	border-radius: 16px;
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: #F6F6F6;
}

.tabLayout .option input {
	display: none;
}

.tabLayout .option label {
	text-transform: uppercase;
	cursor: pointer;
}

.tabLayout .option input:checked+label {
	padding: 6px 10px;
	background: #F6F6F6;
	border-radius: 12px;
	color: #1B1B1B;
}