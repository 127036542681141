.footerLayout {
	border-top: 2px solid #242424;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding: 24px 0;
	gap: 24px;
	width: 100%;
	max-width: 1200px;
	bottom: 0;
	margin-top: 5em;
}

.footerLayout .menus {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.copyright {
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 10px;
	color: #A3A3A3;
	text-align: right;
	margin-right: 16px;
	margin-top: -2em;
}

@media (max-width: 428px) {
	.footerLayout {
		flex-direction: column-reverse;
		row-gap: 1em;
		justify-content: center;
		align-items: center;
	}

	.footerLayout .menus {
		flex-direction: column;
		row-gap: 1em;
	}

	.copyright {
		text-align: center;
	}
}