.appContainer {
	display: flex;
	flex-direction: column;
	margin-top: 32px;
}

.appContainer .titleBar {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	column-gap: 1em;
}

.appContainer .container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	column-gap: 1em;
	margin-top: 24px;
}

.mainViewLayout {
	padding: 24px;
	display: flex;
	flex-direction: column;
	row-gap: 1em;
	background: #1B1B1B;
	border-radius: 16px;
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #F6F6F6;
}

.mainViewLayout .scrollBox {
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 535px;
	height: fit-content;
	display: flex;
	flex-direction: column;
	row-gap: 1em;
}

.mainViewLayout .subViewLayout {
	display: flex;
	flex-direction: column;
	row-gap: 24px;
}

.mainViewLayout .subViewLayout .amountLabel {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #FFFFFF;
}

.mainViewLayout .subViewLayout .amountLabel .btcValue {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 0.5em;
}

.mainViewLayout .subViewLayout .amountLabel .btcValue .value {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.mainViewLayout .subViewLayout .amountLabel .subTitle {
	font-size: 10px;
	line-height: 10px;
	color: #FFFFFF;
	opacity: 0.6;
	margin-top: 4px;
}

.mainViewLayout .orderItem {
	width: calc(100% - 2em);
	display: flex;
	flex-direction: column;
	padding: 16px;
	gap: 16px;
	background: #2E2E2E;
	border-radius: 16px;
}

.mainViewLayout .orderItem .headerLine {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.mainViewLayout .orderItem .buttons {
	display: flex;
	justify-content: space-between;
	column-gap: 1em;
	flex: 1 1;
}

.mainViewLayout .orderItem .statusLabel {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	gap: 2px;
	background: #535353;
	border-radius: 99px;
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	color: #F6F6F6;
	text-transform: capitalize;
}

.mainViewLayout .orderItem .keyAndValue {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	text-transform: uppercase;
	column-gap: 0.5em;
}

.mainViewLayout .orderItem .keyAndValue .subValue {
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	color: #A3A3A3;
}

@media (max-width: 428px) {
	.appContainer {
		width: 100%;
	}

	.appContainer .container {
		flex-direction: column;
		row-gap: 1em;
	}

	.mainViewLayout {
		width: calc(100% - 48px);
	}

	.mainViewLayout .orderItem .buttons {
		display: flex;
		justify-content: space-between;
		column-gap: 1em;
		flex: 1 1;
	}
}