.secretHashLabelLayout {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 12px 8px 12px 12px;
	column-gap: 10px;
	background: #3E3E3E;
	border-radius: 8px;
	width: calc(100% - 20px);
}

.secretHashLabelLayout div {
	display: flex;
	flex-direction: column;
	row-gap: 6px;
}

.secretHashLabelLayout .key {
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	color: #FFFFFF;
	opacity: 0.6;
	text-transform: capitalize;
}

.secretHashLabelLayout .value {
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #FFFFFF;
	display: flex;
	flex-direction: row;
	column-gap: 0.5em;
	align-items: center;
}