.depositModalLayout {
	display: flex;
	flex-direction: column;
	row-gap: 18px;
	justify-content: flex-start;
	align-items: flex-start;
	width: 344px;
}

.depositModalLayout .tipBlock {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	gap: 8px;
	background: #3E3E3E;
	border-radius: 8px;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #A3A3A3;
}