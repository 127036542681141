.headerLayout {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 1200px;
}

.headerLayout .menu {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	column-gap: 1em;
}

.headerLayout .menu a {
	text-transform: uppercase;

}

.headerLayout .menu .label {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	background: #3E3E3E;
	border-radius: 12px;
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: #FFFFFF;
	column-gap: 8px;
	border: none;
	outline: none;
}

.optionLayout {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	column-gap: 0.5em;
}

.optionLayout .title {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: baseline;
	column-gap: 0.5em;
}

.optionLayout .title .subTitle {
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	color: #FFFFFF;
	opacity: 0.7;
}

@media (max-width: 428px) {
	.headerLayout .menu .label span {
		display: none;
	}

	.optionLayout .title {
		display: none;
	}
}