#root {
  width: 100%;
  max-width: 1200px;
}

.App {
  width: 100%;
  padding-top: 39px;
}

.appView {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tinyButtonPrimary {
  border: none;
  outline: none;
  padding: 2px 12px 2px 10px;
  gap: 4px;
  background: #F6F6F6;
  border-radius: 8px;
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #1B1B1B;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  cursor: pointer;
}

.tinyButton {
  text-transform: capitalize;
  padding: 3px 12px 3px 10px;
  background: #4E4E4E;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: none;
  outline: none;
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #F6F6F6;
  cursor: pointer;
}

.smallButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 14px;
  gap: 4px;
  background: #F6F6F6;
  border-radius: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #1B1B1B;
  border: none;
  outline: none;
  text-transform: capitalize;
}

.smallButton:disabled {
  filter: grayscale(100%);
  cursor: not-allowed;
}

.fullwidthButton {
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  padding: 12px;
  background: #3E3E3E;
  border-radius: 12px;
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #F6F6F6;
  text-transform: capitalize;
}

.fullwidthButtonWhite {
  border: none;
  outline: none;
  padding: 12px;
  gap: 8px;
  width: 100%;
  background: #F6F6F6;
  border-radius: 12px;
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #1B1B1B;
  cursor: pointer;
  text-transform: capitalize;
}

.fullwidthButton:disabled {
  filter: grayscale(100%);
  cursor: not-allowed;
}

.fullwidthButtonWhite:disabled {
  cursor: not-allowed;
}

.tokenOptionLayout {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
}

.tokenOptionLayout .tokenSymbol {
  text-transform: uppercase;
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
}

.tokenOptionLayout .tokenName {
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #FFFFFF;
  opacity: 0.6;
  text-transform: capitalize;
}

.multiValues {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 0.5em;
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #F6F6F6;
}

.multiValues .subValue {
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #A3A3A3;
}

@media (max-width: 428px) {
  #root {
    max-width: 100%;
  }

  .App {
    padding-top: 23px;
    width: calc(100% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }

  /* .fullwidthButton {
    width: 50%;
  }

  .fullwidthButtonWhite {
    width: 50%;
  } */
}