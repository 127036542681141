.keyAndValueInLineLayout {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.keyAndValueInLineLayout .key {
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	color: #FFFFFF;
	opacity: 0.6;
	text-transform: capitalize;
}

.keyAndValueInLineLayout .value {
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #FFFFFF;
	display: flex;
	flex-direction: row;
	column-gap: 0.5em;
	align-items: center;
}

@media (max-width: 428px) {
	.keyAndValueInLineLayout {
		column-gap: 1em;
		align-items: flex-start;
	}

	.keyAndValueInLineLayout .value {
		word-wrap: anywhere;
		text-align: right;
	}
}