.menuLayout {
	position: relative;
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	padding: 10px;
	background: #3E3E3E;
	border-radius: 12px;
	border: none;
	outline: none;
	color: white;
	cursor: pointer;
}

.menuLayout .options {
	display: none;
	position: absolute;
	top: 45px;
	right: 0;
	padding: 8px;
	background: #3E3E3E;
	border-radius: 12px;
	justify-content: flex-start;
	flex-direction: column;
	align-items: flex-start;
}