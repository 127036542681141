.tooltipIcon {
	width: 12px;
	height: 12px;
	background: #A3A3A3;
	font-size: 10px;
	color: black;
	position: relative;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	font-weight: bolder;
	cursor: pointer;
	margin: 0 5px;
}

.tooltipContent {
	position: absolute;
	padding: 16px 16px 20px;
	background: #2E2E2E;
	border-radius: 12px;
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #F6F6F6;
	width: 240px;
	z-index: 99;
}