.amountInputLayout {
	margin: 0;
	/* padding: 1em; */
	border-radius: 1em;
	row-gap: 0.5em;
	display: flex;
	flex-direction: column;
}

.amountInputLayout .scanner {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	font-family: 'Lexend', sans-serif;
	z-index: 100;
	background-color: black;
}

.amountInputLayout .scanner .description {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	gap: 18px;
	width: 276px;
	left: 32px;
	top: calc(50% - 230px/2);
	background: #1B1B1B;
	border-radius: 24px;
	position: absolute;
}

.amountInputLayout .scanner .description .title {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 12px;
	text-transform: capitalize;
}

.amountInputLayout .scanner #scanner {
	width: 100%;
	height: 100%;
}

.amountInputLayout .line {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	column-gap: 0.5em;
}

.amountInputLayout .amountInputTitleBar {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
}

.amountInputLayout .amountInputTitleBar .values {
	font-family: 'Lexend', sans-serif;
	text-align: right;
}

.amountInputLayout .amountInputTitleBar .values .subValue {
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	color: #FFFFFF;
	opacity: 0.6;
}

.amountInputLayout .amountInputTitleBar .balanceBar {
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	color: #FFFFFF;
	text-transform: capitalize;
}

.amountInputLayout .amountInputTitleBar .title {
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	text-transform: capitalize;
	color: #FFFFFF;
	display: flex;
	align-items: center;
}

.amountInputLayout .amountInputTitleBar .numberInput {
	width: 100%;
	flex: 1 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	row-gap: 4px;
}

.amountInputLayout .amountInputTitleBar .numberInput input {
	width: 100%;
	background-color: transparent;
	padding: 0.25em;
	border: none;
	outline: none;
	text-align: right;
	appearance: textfield;
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: #FFFFFF;
}

.amountInputLayout .amountInputTitleBar .numberInput input::-webkit-inner-spin-button {
	appearance: none;
}

.amountInputLayout .stringInput {
	padding: 6px 6px 6px 12px;
	background: #3E3E3E;
	border-radius: 12px;
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	display: flex;
	flex-direction: row;
	gap: 0.5em;
	flex: 1 1;
	width: 532px;
}

.amountInputLayout .stringInput input {
	border: none;
	outline: none;
	appearance: textfield;
	background-color: transparent;
	padding: 0;
	margin: 0;
	width: 100%;
	color: #F6F6F6;
}

@media (max-width: 428px) {
	.amountInputLayout .stringInput {
		width: 100%;
	}

	.amountInputLayout .scanner .description {
		top: auto !important;
		left: auto !important;
		width: calc(100% - 5em);
		margin: 0 1em;
		bottom: 1em;
	}
}